import React, { useState } from "react";
import { Tree } from "primereact/tree";

export const TreeComponent = () => {
  const [nodes, setNodes] = useState([
    {
      key: "0",
      label: "Ejemplo gupo Kpis 1",
      icon: "pi pi-inbox",
      children: [
        { key: "0-0", label: "Ejemplo Kpi 1", icon: "pi pi-file" },
        { key: "0-1", label: "Nombre del segundo", icon: "pi pi-file" },
        { key: "0-2", label: "Otro ejemplo Kpi", icon: "pi pi-file" },
        { key: "0-3", label: "Test 4 Kpi", icon: "pi pi-file" },
      ],
    },
    {
      key: "1",
      label: "Otro ejemplo de grupo de Kpis",
      icon: "pi pi-inbox",
      children: [
        { key: "1-0", label: "Ejemplo Kpi 1", icon: "pi pi-file" },
        { key: "1-1", label: "Nombre del segundo", icon: "pi pi-file" },
        { key: "1-2", label: "Otro ejemplo Kpi", icon: "pi pi-file" },
        { key: "1-3", label: "Test 4 Kpi", icon: "pi pi-file" },
      ],
    },
    {
      key: "2",
      label: "Grupo Kpis 4816",
      icon: "pi pi-inbox",
      children: [
        { key: "2-0", label: "Ejemplo Kpi 1", icon: "pi pi-file" },
        { key: "2-1", label: "Nombre del segundo", icon: "pi pi-file" },
        { key: "2-2", label: "Otro ejemplo Kpi", icon: "pi pi-file" },
        { key: "2-3", label: "Test 4 Kpi", icon: "pi pi-file" },
      ],
    },
    {
      key: "3",
      label: "Test Grupo",
      icon: "pi pi-inbox",
      children: [
        { key: "3-0", label: "Ejemplo Kpi 1", icon: "pi pi-file" },
        { key: "3-1", label: "Nombre del segundo", icon: "pi pi-file" },
        { key: "3-2", label: "Otro ejemplo Kpi", icon: "pi pi-file" },
        { key: "3-3", label: "Test 4 Kpi", icon: "pi pi-file" },
      ],
    },
  ]);

  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const onNodeSelect = (e) => {
    setSelectedNodeKey(e.node.key);
  };

  const onNodeUnselect = (e) => {
    setSelectedNodeKey(null);
  };

  return (
    <Tree
      value={nodes}
      selectionMode="single"
      selectionKeys={selectedNodeKey}
      onSelectionChange={(e) => setSelectedNodeKey(e.value)}
      onSelect={onNodeSelect}
      onUnselect={onNodeUnselect}
      filter
    />
  );
};
