import React, { useEffect, useState } from "react";
import {
  Button,
  InputSwitch,
  InputText,
  Toolbar,
  Dropdown,
  InputTextarea,
} from "primereact";

export const KpiPanelContain = ({ onInputChange, closePanel }) => {
  const dataTypes = [
    { value: "boolean" },
    { value: "number" },
    { value: "string" },
  ];

  const [dataType, setDataType] = useState(null);

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle kpi</div>;
  };

  const handleSave = () => {};

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={""}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              autoComplete="off"
              placeholder="Ingresa el nombre del kpi"
            />
          </div>

          <div className="field switch">
            <InputSwitch
              checked={false}
              onChange={(e) => onInputChange(e, "active")}
            />
            <label htmlFor="active">Estado</label>
          </div>
        </div>

        <div className="field">
          <label htmlFor="dataType">Tipo de dato</label>
          <Dropdown
            value={dataType}
            onChange={(e) => setDataType(e.value)}
            options={dataTypes}
            optionLabel="value"
            placeholder="Elige el tipo de dato"
          />
        </div>

        <div className="field">
          <label htmlFor="description">Descripción</label>
          <InputTextarea
            value={""}
            onChange={(e) => onInputChange(e, "description")}
            rows={5}
            cols={30}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
