import React, { useState, useEffect, useRef } from "react";
import { Paginator } from "primereact/paginator";
import { KpiFormsPanelContain } from "./KpiFormsPanelContain";
import { KpiFormsTable } from "./KpiFormsTable";
import { Panel } from "../_slidePanel/Panel";

export default function KpiFormsAbm({ resetView }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const customers = [
    {
      id: "c3b91951-c2a3-014e-49c2-8cc2a6c39bc3",
      name: "Formulario ejemplo 1",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Admin",
    },
    {
      id: "40c3a145-c3a0-003c-4152-c299c298c298",
      name: "Nombre del segundo form",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Admin",
    },
    {
      id: "c2901f52-6fc3-b5c2-9f4b-c387c288c29f",
      name: "Otro formulario",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Test",
    },
    {
      id: "c2b0093d-c28b-c388-c390-436dc2a252c3",
      name: "Ejemplo 4",
      createDate: "09/09/2024",
      updateDate: "10/09/2024",
      grupos: [],
      user: "Test",
    },
  ];

  const onInputChange = (e, name) => {
    let val = e.target && e.target.value;
  };

  const footerPage = (
    <div className="flex-paginator">
      <div></div>
      <div>
        <Paginator
          first={1}
          rows={10}
          totalRecords={4}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={null}
        ></Paginator>
      </div>
      <div></div>
    </div>
  );

  const closePanel = () => {
    setIsOpen(false);
  };

  const openNew = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 8);
  };

  return (
    <>
      <div className="card">
        <KpiFormsTable
          openNew={openNew}
          customers={customers}
          loading={loading}
          footerPage={footerPage}
          resetView={resetView}
        />
        <Panel isOpen={isOpen} closePanel={closePanel}>
          <KpiFormsPanelContain
            onInputChange={onInputChange}
            closePanel={closePanel}
          />
        </Panel>
      </div>
    </>
  );
}
