import { PickList } from "primereact/picklist";

const PicklistComp = ({
  sourceList,
  setSourceList,
  targetList,
  setTargetList,
}) => {
  /** pickList **/
  const itemTemplate = (item) => {
    return <div>{item.name}</div>;
  };

  const onChangeList = (event) => {
    setSourceList(event.source);
    setTargetList(event.target);
  };

  /** */
  return (
    <PickList
      source={sourceList}
      target={targetList}
      itemTemplate={itemTemplate}
      onChange={onChangeList}
      sourceHeader="Disponibles"
      targetHeader="Seleccionados"
      sourceStyle={{ height: "220px" }}
      targetStyle={{ height: "220px" }}
      filterBy="name"
      sourceFilterPlaceholder="Buscar por nombre"
      targetFilterPlaceholder="Buscar por nombre"
      showSourceControls={false}
      showTargetControls={false}
    />
  );
};

export default PicklistComp;
