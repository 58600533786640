import React, { useEffect, useState } from "react";
import { Button, InputSwitch, InputText, Toolbar } from "primereact";
import PicklistComp from "./PicklistComp";

export const KpiGroupsPanelContain = ({ onInputChange, closePanel }) => {
  const kpisList = [
    {
      id: "c3b91951-c2a3-014e-49c2-8cc2a6c39bc3",
      name: "Ejemplo Kpi 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel egestas dolor, nec dignissim metus.",
      dataType: "stirng",
    },
    {
      id: "40c3a145-c3a0-003c-4152-c299c298c298",
      name: "Nombre del segundo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel egestas dolor, nec dignissim metus.",
      dataType: "boolean",
    },
    {
      id: "c2901f52-6fc3-b5c2-9f4b-c387c288c29f",
      name: "Otro ejemplo Kpi",
      description: "Donec vel egestas dolor, nec dignissim metus. ",
      dataType: "number",
    },
    {
      id: "c2b0093d-c28b-c388-c390-436dc2a252c3",
      name: "Test 4 Kpi",
      description:
        "Donec vel egestas dolor, nec dignissim metus. Donec augue elit, rhoncus ac sodales id, porttitor vitae est.",
      dataType: "number",
    },
  ];

  const [sourceList, setSourceList] = useState(kpisList);
  const [targetList, setTargetList] = useState([]);

  const leftToolbarTemplate = () => {
    return <div className="panel-title">Detalle grupo Kpis</div>;
  };

  const handleSave = () => {};

  const rightToolbarTemplate = () => {
    return (
      <>
        <div className="actions">
          <Button
            icon="pi pi-check"
            label="Guardar"
            className="p-button-success"
            onClick={() => handleSave()}
          />
          <Button
            icon="pi pi-times"
            label="Cancelar"
            onClick={() => closePanel()}
          />
        </div>
      </>
    );
  };

  const formTemplate = (
    <div className="body">
      <div className="column">
        <div className="row name-active">
          <div className="field name">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              value={""}
              onChange={(e) => onInputChange(e, "name")}
              required
              autoFocus
              autoComplete="off"
              placeholder="Ingresa el nombre del grupo"
            />
          </div>

          <div className="field switch">
            <InputSwitch
              checked={false}
              onChange={(e) => onInputChange(e, "active")}
            />
            <label htmlFor="active">Estado</label>
          </div>
        </div>

        <div className="field">
          <label htmlFor="kpis">Kpis</label>
          <PicklistComp
            sourceList={sourceList}
            setSourceList={setSourceList}
            targetList={targetList}
            setTargetList={setTargetList}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="slide-panel">
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      {formTemplate}
    </div>
  );
};
